import { createMutableMap } from 'modules-core/utility'
import { iAudioSystem } from 'modules-core/audioSystem/audioSystem'
import { Sample } from 'modules-core/sampleSystem/types'
import { createSampleSpawnSystem } from 'modules-core/sampleSystem/sampleSpawnSystem'
import { createUuidSystem, iUuidSystem } from 'modules-core/uuidSystem/uuidSystem'
import { ParseExt } from 'modules-core/utility'
import { AudioEffect } from 'modules-core/audioEffectSystem'
import { UnitySocket } from 'modules-core/socket/types'
import getServerTime from 'modules-core/utility/serverTime'
import { eventSystem } from 'modules-core/eventSystem'
import { filterBySampleId } from 'modules-core/elementSystem'

// Register events.
eventSystem.add('stopSample')

interface iArgs {
	audioSystem: iAudioSystem
	uuidSystem?: iUuidSystem
}

export const createSampleSystem = ({ audioSystem, uuidSystem }: iArgs) => {
	uuidSystem = uuidSystem || createUuidSystem()

	const sampleMap = createMutableMap<string, Sample.Instance>() as Sample.MutableMap

	const sampleSpawnSystem = createSampleSpawnSystem({ audioSystem, uuidSystem, sampleMap })

	const getSamplesWithSampleId = (map: Sample.UUIDMap, sampleId: number) =>
		[...map].filter(([key, sample]) => sample.sampleId === sampleId)

	const sampleSystem = {
		map: sampleMap,
		getSamples: () => sampleMap.getRaw(),
		getSamplesWithSampleId:(sampleId: number)=>
			filterBySampleId(sampleMap, sampleId),
		...sampleSpawnSystem,
		removeSample: (uuid: string) =>
			sampleMap.get().get(uuid).dispose(),
		getStopWait: (sampleId: number) => {
			// Get the wait time until the last active sample matching `sampleId` will stop.
			const now = getServerTime()
			let stopTime: number
			sampleSystem.getSamplesWithSampleId(sampleId).filter(([, sample]) =>
				sample.startTime <= now && now < sample.startTime + sample.duration
			).forEach(([, sample]) => {
				const thisStopTime = sample.startTime + sample.duration
				if (!stopTime || thisStopTime > stopTime) {
					stopTime = thisStopTime
				}
			})
			// TODO: Use the server provided fadeout duration instead of a 3000ms hard limit.
			return Math.min(Math.max(stopTime - now, 0), 3000) || 0
		},
		stopPlaylistEntryByPlaylistEntryId: (playlistEntryId: number) => {
			[...sampleSystem.getSamples().values()]
				.filter((sample) => sample.playlistEntryId === playlistEntryId)
				.forEach((sample) => sample.dispose())
		},
		stopDirectSampleBySampleId: (sampleId: number) => {
			sampleSystem.getSamplesWithSampleId(sampleId).forEach(([, sample]) => {
				if (!sample.elementId && !sample.playlistEntryId) {
					sample.dispose()
				}
			})
		},
		stopSampleBySampleUuid: (sampleUuid: string) => {
			sampleMap.getRaw().get(sampleUuid).dispose()
		},
		stopAllSamples: () => {
			return Promise.all([...sampleMap.getRaw()].map(([, sample]) => sample.dispose()))
		},
		playSample: (sampleData: UnitySocket.Sample) =>
			sampleSystem.spawnSampleFromParams({ params: ParseExt.socketSampleToSampleParams(sampleData) }),
		playSampleAtTime: (sampleData: UnitySocket.Sample, startTime?: number) =>
			sampleSystem.spawnSampleFromParams({ params: ParseExt.socketSampleToSampleParams(sampleData), startTime }),
		playSampleWithReverb: (sampleData: UnitySocket.Sample, presetName: AudioEffect.Preset = 'Cave', startTime?: number) =>
			sampleSystem.spawnSampleFromParams({ params: { ...ParseExt.socketSampleToSampleParams(sampleData), presetName }, startTime }),
		stopSample: ({ id }: UnitySocket.Server.StopSample) =>
			sampleSystem.stopDirectSampleBySampleId(Number(id)),

	}
	return sampleSystem

}

export type iSampleSystem = ReturnType<typeof createSampleSystem>
